import $post from '@/request/productApis.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// import $comjs from '@/minxin/commonJs.js'
// import axios from 'axios'
const uploadMaterialInfo = {
  name: 'uploadMaterialInfo',
  components: {
    ElImageViewer
  },
  props: {
    IDX: {
      type: Number,
      default: 0
    }, // 产品id
    RequestType: {
      type: Number,
      default: 0
    } // 1： 首营  2：列表
  },
  data() {
    return {
      UserInfo: {}, // 人员信息
      editProductLoading: false,
      materialdetaisinfo: {
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: '',
        MaterialCertificateList: [{
          IDX: '主键',
          CertificateType: '证件类别的 Code的值',
          CertificateTypeName: '产品证照名称',
          DatetimeType: '证照是否是长期有效期状态 0：否，1：是',
          CertificateStartLine: '证照起始日期',
          CertificateDeadLine: '证照结束日期',
          CertificatePath: '证相路径',
          CertificateName: '注册证号',
          CertificateCompanyName: '注册证企业名称'
        }]
      },
      materialFiles: [],
      IsMore: false
    }
  },
  mounted() {
    this.getProductDetaisInfo(this.IDX)
    // this.getTypes()
  },
  methods: {
    IsMoreFun() {
      this.IsMore = !this.IsMore
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      _this.editProductLoading = true
      if (_this.RequestType === 1) {
        $post.getProductInfo({ FCMIdx: _idx }).then(res => {
          _this.editProductLoading = false
          console.log(res)
          if (res.RetCode === '0') {
            _this.materialdetaisinfo = res.Message
          } else {
            _this.$message.Error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'ProductList1' })
          }
        })
      } else if (_this.RequestType === 2) {
        _this.editProductLoading = false
        $post.GetMaterialRefCompanyDeatil({ FCMIdx: _idx }).then(res => {
          if (res.RetCode === '0') {
            _this.materialdetaisinfo = res.Message
          } else {
            _this.$message.Error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'productInfoList1' })
          }
        })
      }
    },
    getShefType(_type) {
      switch (_type) {
        case 1:
          return { value: 1, text: '年' }
        case 2:
          return { value: 2, text: '月' }
        case 3:
          return { value: 3, text: '日' }
        default: return { value: 0, text: '' }
      }
    },
    getTaxInfo(_tax) {
      switch (_tax) {
        case 0.17:
          return '17'
        case 0.16:
          return '16'
        case 0.13:
          return '13'
        case 0.11:
          return '11'
        case 0.1:
          return '10'
        case 0.06:
          return '6'
        case 0.03:
          return '3'
        case 0.01:
          return '1'
        case 1:
          return '0'
        default: return null
      }
    }
  }
}
export default uploadMaterialInfo
