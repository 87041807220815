import PageHeader from '@/components/PageHeader.vue'
import Steps from '@/components/steps'
// import $post from '@/request/productApis.js'
// import $comjs from '@/minxin/commonJs.js'
// import axios from 'axios'
import uploadMaterialInfo from '@/views/Product/uploadMaterialInfo.vue'
import editCertifiList from '@/views/Product/editCertifiList.vue'
const uploadProductFiles = {
  name: 'uploadProductFiles',
  components: {
    PageHeader,
    Steps,
    uploadMaterialInfo,
    editCertifiList
  },
  data() {
    return {
      UserInfo: {}, // 人员信息
      editProductLoading: false,
      stepsData: [
        {
          id: '1',
          name: '新增商品',
          icon: 'el-icon-document-add'
        },
        {
          id: '2',
          name: '完善商品信息',
          icon: 'el-icon-edit'
        },
        {
          id: '3',
          name: '上传商品证照',
          icon: 'el-icon-upload'
        },
        {
          id: '4',
          name: '等待审核',
          icon: 'el-icon-s-promotion'
        }
      ],
      materialdetaisinfo: {
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: '',
        MaterialCertificateList: [{
          IDX: '主键',
          CertificateType: '证件类别的 Code的值',
          CertificateTypeName: '产品证照名称',
          DatetimeType: '证照是否是长期有效期状态 0：否，1：是',
          CertificateStartLine: '证照起始日期',
          CertificateDeadLine: '证照结束日期',
          CertificatePath: '证相路径',
          CertificateName: '注册证号',
          CertificateCompanyName: '注册证企业名称'
        }]
      },
      active: 2,
      materialFiles: [{
        date: '2016-05-02',
        name: '王小虎',
        id: 1,
        address: '上海市普陀区金沙江路 1518 弄',
        render: () => {
          return '<p>2131312</p>'
        }
      }, {
        date: '2016-05-04',
        name: '王小虎',
        id: 2,
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        id: 3,
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        id: 4,
        address: '上海市普陀区金沙江路 1516 弄'
      }],
      IsMore: false,
      requestType: 0
    }
  },
  created() {
    this.IDX = parseInt(this.$route.params.idx)
    // 1 首营 2：列表
    this.requestType = parseInt(this.$route.params.type)
  },
  mounted() {
  },
  methods: {
    IsMoreFun() {
      this.IsMore = !this.IsMore
    }
  }
}
export default uploadProductFiles
