</<template>
    <div class="merchants">
      <PageHeader :border="false" title="产品首营流程" center>
        <Steps :step="active"  :stepsData="stepsData"/>
      </PageHeader>
      <uploadMaterialInfo :IDX="IDX" :RequestType="requestType"/>
      <!-- 首营的证照列表 -->
      <editCertifiList :IDX="IDX" :RequestType="requestType"/>
      <!-- 已审批通过的证照列表 -->
      <!-- <materialCertificatesInfo v-if="requestType === 1" :IDX="IDX" :RequestType="requestType"/> -->
      <!-- 首营的证照列表 -->
      <!-- <productCertificateList v-if="requestType === 2" :IDX="IDX" :RequestType="requestType"/> -->
    </div>
</template>
<script>
import uploadProductFiles from '@/minxin/product/uploadProductFiles.js'
export default uploadProductFiles
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>
