</<template>
    <div class=" p-lr-20 p-tb-20" v-loading.fullscreen.lock="editProductLoading">
      <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right"></i> 产品基本信息
         <div style="float:right;display:inline-block;margin-right:10px;line-height:110%">
                <el-link type="primary" class="m-right-10" v-if="!IsMore" @click="IsMoreFun" :underline="false">更多</el-link>
                <el-link type="primary" v-if="!IsMore" @click="$router.push({ name: 'productEdit1', query: { idx: materialdetaisinfo.IDX }, params: { type: RequestType} })" :underline="false">修改</el-link>
                <!-- <el-link type="primary" v-else @click="IsMoreFun" :underline="false">收起</el-link> -->
          </div>
      </div>
      <el-form :inline="true" :model="materialdetaisinfo" size="mini"  class="demo-form-inline"  label-position="left">
            <div class="InfoList1 width_33pct" v-if='IsMore'>
              <ul>
              <el-form-item label="物料名称"  class="mateialdetails" >
                <span>{{materialdetaisinfo.MaterialName}}</span>
              </el-form-item>
              <el-form-item label="物料简称"  class="mateialdetails">
                {{materialdetaisinfo.NickName}}
              </el-form-item>
              <el-form-item label="物料编号"  class="mateialdetails">
                {{materialdetaisinfo.InvCode}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="物料类型"  class="mateialdetails">
                {{materialdetaisinfo.MaterialTypeName}} /{{materialdetaisinfo.MaterialSubTypeName}}
              </el-form-item>
              <el-form-item label="物料分类" class="mateialdetails">
                {{materialdetaisinfo.InvTypeName}}
              </el-form-item>
              <el-form-item label="品牌"  class="mateialdetails">
                {{materialdetaisinfo.BrandName}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="规格/型号"  class="mateialdetails">
                {{materialdetaisinfo.Specification}}
              </el-form-item>
              <el-form-item label="单位"  class="mateialdetails" >
                {{materialdetaisinfo.StockUnitN}}
              </el-form-item>
              <el-form-item label="最小包装"  class="mateialdetails">
                {{materialdetaisinfo.MinPacking}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="注册证号/批准文号/备案凭证号"  class="mateialdetails lh_120">
                {{materialdetaisinfo.RegisterNo}}
              </el-form-item>
              <el-form-item label="注册证起始日期"  class="mateialdetails">
                <!-- {{materialdetaisinfo.RegisterStart}} -->
                 {{ (materialdetaisinfo.RegisterStart !== null && materialdetaisinfo.RegisterStart.length > 0) ? $minCommon.formatDate(new Date(materialdetaisinfo.RegisterStart), 'yyyy-MM-dd') : '' }}
              </el-form-item>
              <el-form-item label="注册证截止日期"  class="mateialdetails">
                <!-- {{materialdetaisinfo.RegisterEnd}} -->
                 {{ (materialdetaisinfo.RegisterEnd !== null && materialdetaisinfo.RegisterEnd.length > 0) ? $minCommon.formatDate(new Date(materialdetaisinfo.RegisterEnd), 'yyyy-MM-dd') : '' }}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="生产厂家"  class="mateialdetails">
                {{materialdetaisinfo.ProductFactory}}
              </el-form-item>
              <el-form-item label="产品有效期"  class="mateialdetails">
                 <span v-if="materialdetaisinfo.ShelfLifeType === 0">长期</span>
              <span v-if="materialdetaisinfo.ShelfLifeType > 0">{{ RequestType === 1 ?  materialdetaisinfo.ShelfLifeNum :  materialdetaisinfo.ShelfLifeNums }} {{ getShefType(materialdetaisinfo.ShelfLifeType).text}}</span>
              </el-form-item>
              <el-form-item label="运输条件" class="mateialdetails">
                {{materialdetaisinfo.TransportConditionName}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="生产企业许可证号"  class="mateialdetails">
                {{materialdetaisinfo.ProductLicenseNo}}
              </el-form-item>
              <el-form-item label="原产地"  class="mateialdetails">
                {{materialdetaisinfo.SourceAreaName}}
              </el-form-item>
              <el-form-item label="存货代码"  class="mateialdetails">
                {{materialdetaisinfo.StorageNo}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="医疗器械注册人"  class="mateialdetails">
                {{materialdetaisinfo.RegisterPerson}}
              </el-form-item>
              <el-form-item label="药品上市许可人"  class="mateialdetails">
                {{materialdetaisinfo.MaterialPermissionPerson}}
              </el-form-item>
              <el-form-item label="物料默认仓库"  class="mateialdetails">
                {{materialdetaisinfo.StockCode}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="储存条件" class="mateialdetails">
                {{materialdetaisinfo.StorageConditionName}}
              </el-form-item>
              <el-form-item  label="DI码" class="mateialdetails">
                <span>{{ materialdetaisinfo.DINum }}</span>
              </el-form-item>
              <el-form-item label="备注"  class="mateialdetails">
                {{materialdetaisinfo.MaterialRemark}}
              </el-form-item>
              </ul>
              <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right"></i> 财务信息</div>
              <el-form-item label="产品税率(%)" class="mateialdetails">
                {{getTaxInfo(materialdetaisinfo.Tax)}}
              </el-form-item>
              <el-form-item label="计划价格(元)" class="mateialdetails">
                {{materialdetaisinfo.PlanPrice}}
              </el-form-item>
              <el-form-item label="发布价格(元)"  class="mateialdetails">
                {{materialdetaisinfo.Price}}
              </el-form-item>
            </div>
            <div class="InfoList1 width_33pct" v-if="!IsMore">
              <ul>
              <el-form-item label="物料名称"  class="mateialdetails">
                <span>{{materialdetaisinfo.MaterialName}}</span>
              </el-form-item>
              <el-form-item label="物料类型"  class="mateialdetails">
                {{materialdetaisinfo.MaterialTypeName}} /{{materialdetaisinfo.MaterialSubTypeName}}
              </el-form-item>
              <el-form-item label="物料编号"  class="mateialdetails">
                {{materialdetaisinfo.InvCode}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="规格/型号"  class="mateialdetails">
                {{materialdetaisinfo.Specification}}
              </el-form-item>
              <el-form-item label="包装单位"  class="mateialdetails">
                {{materialdetaisinfo.StockUnitN}}
              </el-form-item>
              <el-form-item label="品牌"  class="mateialdetails">
                {{materialdetaisinfo.BrandID}}
              </el-form-item>
              </ul>
              <ul>
              <el-form-item label="注册证号"  class="mateialdetails">
                {{materialdetaisinfo.RegisterNo}}
              </el-form-item>
              <el-form-item label="产品有效期"  class="mateialdetails">
              <span v-if="materialdetaisinfo.ShelfLifeType === 0">长期</span>
              <span v-if="materialdetaisinfo.ShelfLifeType > 0">{{ RequestType === 1 ?  materialdetaisinfo.ShelfLifeNum :  materialdetaisinfo.ShelfLifeNums }} {{ getShefType(materialdetaisinfo.ShelfLifeType).text}}</span>
                <!-- {{materialdetaisinfo.ShelfLifeNum}} {{materialdetaisinfo.ShelfLifeTypeStr}} -->
              </el-form-item>
              <el-form-item label="储存条件" class="mateialdetails">
                {{materialdetaisinfo.StorageConditionName}}
              </el-form-item>
              </ul>
            </div>
            </el-form>
      <div v-if="IsMore" class="up flex j-center">
      <i class="el-icon-arrow-up" @click="IsMoreFun" />
      </div>
    </div>
</template>
<script>
import uploadMaterialInfo from '@/minxin/product/uploadMaterialInfo.js'
export default uploadMaterialInfo
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>
